/* unplugin-vue-components disabled */import { render, staticRenderFns } from "./FormHighlight.vue?vue&type=template&id=48b7f4df&scoped=true"
import script from "./FormHighlight.vue?vue&type=script&lang=ts&setup=true&generic=T"
export * from "./FormHighlight.vue?vue&type=script&lang=ts&setup=true&generic=T"
import style0 from "./FormHighlight.vue?vue&type=style&index=0&id=48b7f4df&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "48b7f4df",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {IconButton: require('/code/src/components/button/IconButton.vue').default})
