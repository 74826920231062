import { VFadeTransition } from 'vuetify/lib/components/transitions';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', _vm._g({
    staticClass: "form-highlight",
    class: {
      'highlight highlight--diff': _setup.initialValue !== undefined && _setup.diff && !_vm.disabled
    }
  }, _vm.$listeners), [_vm._t("default"), _vm._v(" "), _c(VFadeTransition, {
    attrs: {
      "leave-absolute": ""
    }
  }, [_setup.initialValue !== undefined && _setup.diff && !_vm.disabled ? _c('span', {
    staticClass: "p-absolute",
    staticStyle: {
      "top": "0",
      "right": "0"
    }
  }, [_c('IconButton', {
    attrs: {
      "color": "secondary-text",
      "icon": "mdi-arrow-u-left-top",
      "tooltip": _vm.$t('action.undo')
    },
    on: {
      "click": function click($event) {
        _setup.emits('update:value', _setup.cloneDeep(_setup.initialValue));
      }
    }
  })], 1) : _vm._e()])], 2);
};
var staticRenderFns = [];
export { render, staticRenderFns };